import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};
let vapidKey = '';

/* Connect to firebase: test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyDp3iM3cUY3rLCtLdWrV3OUV8vfZzVHnXo',
		authDomain: 'cgl-mazeout-test.firebaseapp.com',
		databaseURL: 'https://cgl-mazeout-test.firebaseio.com',
		projectId: 'cgl-mazeout-test',
		storageBucket: 'cgl-mazeout-test.appspot.com',
		messagingSenderId: '832980945862',
		appId: '1:832980945862:web:c6cf7f4747f3e895e040c7'
	};
	vapidKey = 'BCdupwFkmaU6d_xgSv-y1vEUYzNQx3UJEbCXU9kPmTL2ziCaNGdGH9G4ChdnfcHm7RDKz3-sjy5G81O3T5a52-E';
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCR8HsX19uiy2qCa3ybRdXz9gm-OHwpMqU',
		authDomain: 'cgl-mazeout-production.firebaseapp.com',
		databaseURL: 'https://cgl-mazeout-production.firebaseio.com',
		projectId: 'cgl-mazeout-production',
		storageBucket: 'cgl-mazeout-production.appspot.com',
		messagingSenderId: '599444636911',
		appId: '1:599444636911:web:76508d5d96d0495c7b871a',
		measurementId: 'G-ZJBZ0PTEC1'
	};
	vapidKey = 'BNamXXcEBGlvfbU8R9zFhwK4rY0QkryMt3ziuDzCiUtp85OxFOku0r6RDBMeOAJT6TQPlpwvkbTRMZ32cJzKkMk';
}

/* Connect to firebase: english version */
if (env === 'english') {
	firebaseConfig = {
		apiKey: 'AIzaSyCBXIyr3GlrDfPlGxo9I2sDePMSiquKhF4',
		authDomain: 'cgl-mazeout-english.firebaseapp.com',
		projectId: 'cgl-mazeout-english',
		storageBucket: 'cgl-mazeout-english.appspot.com',
		messagingSenderId: '605847319852',
		appId: '1:605847319852:web:98ec2739f8d337295f6889',
		measurementId: 'G-YP94RD8JQH'
	};
	vapidKey = 'BC_HBVvVsjHQVm9VK6sZR-ke6S5xEZotETM0DiIULYzwtyKESrH_MDx43t-eGw3jrTKkOCv5rXOkZfTtGbtCcP4';
}


/* Initialize firebase */
const firebaseApp = firebase.initializeApp(firebaseConfig);

/* Initialize messaging */
// https://blog.logrocket.com/push-notifications-react-firebase/
// https://console.firebase.google.com/u/0/project/cgl-mazeout-test/notification/compose
const messaging = getMessaging(firebaseApp);

/* Initialize analytics */
if (env === 'production' || env === 'english') {
	firebase.analytics();
}

export default firebase.firestore().enablePersistence()
	.then(() => {
		console.log('Firestore persistence enabled!');
		return firebase.firestore();
	})
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
		} else if (error.code === 'unimplemented') {
			console.error('The current browser does not support all of the features required to enable persistence');
		} else {
			console.error('Unknown persistence error: ', error);
		}
		return firebase.firestore();
	});

/**
 * Check if notifications are allowed
 * If allowed: get token
 * If not: ask to allow 
 * */
export const tryGetToken = () => {
	// eslint-disable-next-line max-len
	return getToken(messaging, {vapidKey: vapidKey});
};

/* Listen for notifications whhile app is active in foreground */
export const onMessageListener = () => {
	return new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
};

