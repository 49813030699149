import appConfig from 'config/app.config';

/**
 * Get time played
 * @param {number} startTimeSecs 
 * @param {bool} isQuickGame 
 */
function getTimePlayed(startTimeSecs, isQuickGame) {
	let gameDuration = (startTimeSecs !== null  
		? (Math.floor(new Date().getTime() / 1000) - startTimeSecs)
		: 0
	);
	if (isQuickGame) gameDuration = gameDuration * 10;
	return gameDuration;
}

/**
 * Get available cards in current deck
 * @param {bool} isFreeplay
 * @param {array} decksData
 * @param {string} deckId 
 * @param {number} mazeId
 * @param {array} lockedCards
 * @param {array} cardDetention 
 * @param {object} tagDetention
 */
function getAvailableCardsInDeck(isFreeplay, decksData, deckId, mazeId, lockedCards, cardDetention, tagDetention) {
	let availableCards = [];
	/* Get deck data */
	let deck = (decksData.some((deck) => {return deck.id === deckId;})
		? decksData.filter((deck) => {return deck.id === deckId;})[0]
		: null
	);

	if (deck && deck.cards) {
		availableCards = deck.cards.filter((card) => {
			return (
				// random
				card.isRandom === true && 
				// not locked
				lockedCards.indexOf(card.id) < 0 && 
				// not in detention
				!cardDetention.some((dCard) => {return dCard.id === card.id;}) &&
				(
					// no tags
					!card.hasOwnProperty('tags') || !card.tags.length === 0 ||
					// tags not in detention
					!card.tags.some((tagId) => {return tagDetention.hasOwnProperty(tagId);})
				) &&
				(
					// freeplay, special deck, no maze requirement
					isFreeplay || deckId !== 'basic' || !card.mazeId || 
					// maze requirement met
					(card.mazeId <= mazeId && (!card.mazeIdMax || card.mazeIdMax >= mazeId))
				) 
			);
		});
	}

	return availableCards;
}

/**
 * Pick random card
 * @param {string} deckId 
 * @param {object} playerData 
 * @param {bool} isFreeplay 
 * @param {array} decksData 
 * @param {func} updateCardDetention 
 * @returns 
 */
function pickRandomCard(deckId, cardDetention, playerData, isFreeplay, decksData, updateCardDetention) {
	/* Get available cards from deck */
	let lockedCards = JSON.parse(JSON.stringify(playerData.lockedCards));
	let newCardDetention = JSON.parse(JSON.stringify(cardDetention));
	let availableCards = getAvailableCardsInDeck(
		isFreeplay,
		decksData, 
		deckId, 
		playerData.mazeId,
		lockedCards, 
		newCardDetention,
		playerData.tagDetention
	);

	/* If no cards available, flush cardDetention before getting available cards from deck */
	/* TODO: disable this when we have enough cards */
	/* NOTE: this is triggered inside a loop, if disabled we should handle loops that run out */
	if (availableCards.length === 0) {
		newCardDetention = [];
		availableCards = getAvailableCardsInDeck(
			isFreeplay,
			decksData, 
			deckId, 
			playerData.mazeId, 
			lockedCards,
			newCardDetention,
			playerData.tagDetention
		);
	}

	/* Get random card from available cards */
	const randomIndex = Math.floor(Math.random() * availableCards.length);
	const cardId = availableCards[randomIndex].id;

	/* Update card detention */
	if (deckId !== 'pause') {
		newCardDetention.push({id: cardId, playCount: 0});
		updateCardDetention(newCardDetention);
	}

	return cardId;
}

/**
 * Check if the conditions for completing a mission are met
 * @param {object} missionData 
 * @param {object} playerData 
 * @returns 
 */
function checkIfMissionConditionsAreMet(missionData, playerData) {
	const conditionsAreMet = (
		// Mission not already completed
		!playerData.missionsCompleted.some((mission) => {
			return mission.id === missionData.missionId;
		}) &&
		(
			// No conditions
			missionData.conditionType === 'none' ||
			(
				// Condition: secret known
				missionData.conditionType === 'secret' && 
				playerData.secretsDiscovered.indexOf(missionData.secretId) >= 0
			)
		)
	);
	return conditionsAreMet;
}

/**
 * Check if dream has ended
 * @param {number} startTimeSecs 
 * @param {bool} isQuickGame 
 * @param {object} selectedOption 
 * @param {string} deckId 
 */
function getIsEndOfDream(timePlayedSecs, selectedOption, deckId, availableCards) {
	let isEndOfDream = false;

	/* Next card is random, check time played and deck */
	let nextCardIsRandom = (selectedOption.nextCard.type === 'random');
	if (!isEndOfDream && nextCardIsRandom) {
		/* Not in special deck and not going to special deck */
		if (deckId !== 'party' && selectedOption.nextCard.deckId !== 'party') {
			isEndOfDream = (timePlayedSecs >= appConfig.gameDurationLimitSecs);
		}

		/* Returning to basic deck */
		if (deckId !== 'basic' && selectedOption.nextCard.deckId === 'basic') {
			if (deckId === 'forest' || deckId === 'party') { // TODO: why these conditions?
				isEndOfDream = (timePlayedSecs >= appConfig.gameDurationSoftLimitSecs);
			}
		}
	}

	/* In basic deck and no more cards left */
	if (!isEndOfDream && nextCardIsRandom && selectedOption.nextCard.deckId === 'basic' && availableCards.length < 1) {
		isEndOfDream = true;
	}

	return isEndOfDream;
}



export {
	getTimePlayed,
	getAvailableCardsInDeck,
	pickRandomCard,
	checkIfMissionConditionsAreMet,
	getIsEndOfDream
};