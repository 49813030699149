import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {loginUiTexts, statsUiTexts} from './ui-texts';
import CardsController from 'components/admin/cards/cards-controller';
import ImagesController from 'components/admin/images/images-controller';
import AreasController from 'components/admin/areas/areas-controller';
import TagsController from 'components/admin/tags/tags-controller';
import MissionsController from 'components/admin/missions/missions-controller';
import SurveysController from 'components/admin/surveys/surveys-controller';
import TransferController from 'components/admin/transfer/transfer-controller';
import StatsController from 'components/admin/stats/stats-controller';
import ReserveUsersController from './reserve-users/reserve-users-controller';
import './admin.scss';


const Admin = ({authSessionData, page, goToPage, handleLogout}) => {

	switch (page) {
	case 'cards':
		return <CardsController goToPage={goToPage} />;
	case 'images':
		return <ImagesController goToPage={goToPage} />;
	case 'areas':
		return <AreasController goToPage={goToPage} />;
	case 'tags':
		return <TagsController goToPage={goToPage} />;
	case 'missions':
		return <MissionsController goToPage={goToPage} />;
	case 'surveys':
		return <SurveysController goToPage={goToPage} />;
	case 'transfer':
		return <TransferController goToPage={goToPage} />;
	case 'stats':
		return <StatsController authSessionData={authSessionData} goToPage={goToPage} />;
	case 'reserved-users':
		return <ReserveUsersController goToPage={goToPage} />;
	default:
		let server = 'test';
		if (appConfig.env === 'demo') server = 'demo';
		if (appConfig.env === 'production') server = 'produktion';
		if (appConfig.env === 'english') server = 'english';
		return (
			<div className="Admin">
				<div className="Admin-title">Mazeout - Admin</div>
				<div className="Admin-logout" onClick={() => {handleLogout();}}>{loginUiTexts.logout}</div>
				<div className="Admin-text">Dette er adminpanelet for Mazeout på {server}-serveren.</div>
				{(server !== 'demo' && server !== 'production') && 
					<React.Fragment>
						<div className="Admin-button" onClick={() => {goToPage('areas');}}>Områder</div><br />
						<div className="Admin-button" onClick={() => {goToPage('cards');}}>Kort</div><br />
						<div className="Admin-button" onClick={() => {goToPage('images');}}>Billeder</div><br />
						<div className="Admin-button" onClick={() => {goToPage('tags');}}>Tags</div><br />
						<div className="Admin-button" onClick={() => {goToPage('missions');}}>Missioner</div><br />
						<div className="Admin-button" onClick={() => {goToPage('surveys');}}>Surveys</div><br />
					</React.Fragment>
				}
				<div className="Admin-button" onClick={() => {goToPage('reserved-users');}}>Reserverede brugere</div>
				<br />
				<div className="Admin-button" onClick={() => {goToPage('stats');}}>{statsUiTexts.stats}</div><br />
				{server !== 'demo' && 
					<div className="Admin-button" onClick={() => {goToPage('transfer');}}>Overfør data</div>}
			
				
			</div>
		);
	}
};

Admin.propTypes = {
	authSessionData: PropTypes.object.isRequired,
	page: PropTypes.string.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Admin;