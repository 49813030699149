import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import './transfer.scss';

const Transfer = (props) => {
	let {
		isTransfering,
		isDownloadingStorageFiles,
		isUploading,
		// isUploading2,
		didTransfer,
		areasStatus,
		decksStatus,
		cardsStatus,
		missionsStatus,
		surveysStatus,
		imagesStatus,
		tagsStatus,
		downloadingDBCollection,
		feedbackMsg,
		transferDataAndImages,
		downloadDBData,
		downloadStorageFiles,
		selectFileToUpload,
		// uploadTextToDatabase,
		uploadFileToDatabase,
		goToPage
	} = props;

	
	return (
		<div className="Transfer">
			<div className="Transfer-backBtn" onClick={() => {goToPage('admin');}}>Tilbage</div>

			{(
				appConfig.env !== 'demo' && 
				appConfig.env !== 'production' && 
				appConfig.env !== 'english'
			) && <div className="Transfer-section">
				<div className="Transfer-title">Overfør data fra test-server til demo-server:</div>
				<p>Overfør dæk, kort og billeder - dette vil overskrive, hvad der ligger på demo-serveren nu.</p>
				<div 
					className={'Transfer-btn' + (isTransfering ? ' loading' : '')} 
					onClick={() => {transferDataAndImages();}}
				>Overfør</div>
				{(isTransfering || didTransfer) && <div>
					Områder ... {(areasStatus ? 'ok' : '')}	<br />
					Dæk .... {(decksStatus ? 'ok' : '')}	<br />
					Kort .... {(cardsStatus ? 'ok' : '')}	<br />
					Missioner .... {(missionsStatus ? 'ok' : '')}	<br />
					Surveys .... {(surveysStatus ? 'ok' : '')}	<br />
					Billeder .... {(imagesStatus ? 'ok' : '')}	<br />
					Tags .... {(tagsStatus ? 'ok' : '')}	<br />
				</div>}
			</div>}

			<div className="Transfer-section">
				<div className="Transfer-title">Download data & filer fra {appConfig.env}-server:</div>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'areasData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('areas');}}
				>Download areas data (file: 'areasData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'decksData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('decks');}}
				>Download decks data (file: 'decksData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'cardsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('cards');}}
				>Download cards data (file: 'cardsData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'imagesData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('images');}}
				>Download images data (file: 'imagesData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'missionsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('missions');}}
				>Download missions data (file: 'missionsData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'surveysData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('surveys');}}
				>Download surveys data (file: 'surveysData.json')</p>
				<p
					className={'Transfer-link' + (downloadingDBCollection === 'tagsData' ? ' loading' : '')} 
					onClick={() => {downloadDBData('tags');}}
				>Download tags data (file: 'tagsData.json')</p>

				<p>&nbsp;</p>
				<p
					className={'Transfer-link' + (isDownloadingStorageFiles ? ' loading' : '')} 
					onClick={() => {downloadStorageFiles();}}
				>Download filer (file: 'storage.zip')</p>
			</div>

			{/* {(appConfig.env !== 'production' && appConfig.env !== 'demo') && <div className="Transfer-section">
				<div className="Transfer-title">Overskriv tekster på {appConfig.env}-server:</div>
				<form>
					<input
						type="file"
						accept="application/json"
						name="json"
						className="Transfer-selectFile"
						onChange={(event)=>{selectFileToUpload(event);}}
					/>
					<div
						className={'Transfer-uploadFile' + (isUploading2 ? ' loading' : '')}
						onClick={()=>{uploadTextToDatabase();}}
					>Upload file</div>
					<div className="Transfer-feedback">{feedbackMsg}</div>
				</form>
			</div>} */}

			{(appConfig.env === 'production' || appConfig.env === 'english') && <div className="Transfer-section">
				<div className="Transfer-title">Upload data til {appConfig.env}-server:</div>
				<form>
					<input
						type="file"
						accept="application/json"
						name="json"
						className="Transfer-selectFile"
						onChange={(event)=>{selectFileToUpload(event);}}
					/>
					<div
						className={'Transfer-uploadFile' + (isUploading ? ' loading' : '')}
						onClick={()=>{uploadFileToDatabase();}}
					>Upload file</div>
					<div className="Transfer-feedback">{feedbackMsg}</div>
				</form>

			</div>}

		
		</div>
	);
};

Transfer.propTypes = {
	isTransfering: PropTypes.bool.isRequired,
	isDownloadingStorageFiles: PropTypes.bool.isRequired,
	isUploading: PropTypes.bool.isRequired,
	// isUploading2: PropTypes.bool.isRequired,
	didTransfer: PropTypes.bool.isRequired,
	areasStatus: PropTypes.bool.isRequired,
	decksStatus: PropTypes.bool.isRequired,
	cardsStatus: PropTypes.bool.isRequired,
	missionsStatus: PropTypes.bool.isRequired,
	surveysStatus: PropTypes.bool.isRequired,
	imagesStatus: PropTypes.bool.isRequired,
	tagsStatus: PropTypes.bool.isRequired,
	downloadingDBCollection: PropTypes.any,
	feedbackMsg: PropTypes.string,
	transferDataAndImages: PropTypes.func.isRequired,
	downloadDBData: PropTypes.func.isRequired,
	downloadStorageFiles: PropTypes.func.isRequired,
	selectFileToUpload: PropTypes.func.isRequired,
	// uploadTextToDatabase: PropTypes.func.isRequired,
	uploadFileToDatabase: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired
};

export default Transfer;