import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/app';
import './index.scss';

/* Render App */
ReactDOM.render(<App />, document.getElementById('root'));

/* Register service worker */
serviceWorker.register();