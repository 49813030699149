function getPlayerData(id, playerDbData) {

	const playerData = {
		id: id,
		cardDetention: (playerDbData.hasOwnProperty('cardDetention') ? playerDbData.cardDetention : []),
		created: playerDbData.created,
		gameEnded: (playerDbData.hasOwnProperty('gameEnded') ? playerDbData.gameEnded : null),
		gameStarted: (playerDbData.hasOwnProperty('gameStarted') ? playerDbData.gameStarted : null),
		gamesPlayed: (playerDbData.hasOwnProperty('gamesPlayed') ? playerDbData.gamesPlayed : []),
		introSeen: (playerDbData.hasOwnProperty('introSeen') ? playerDbData.introSeen : false),
		lastLogin: playerDbData.lastLogin,
		lockedCards: (playerDbData.hasOwnProperty('lockedCards') ? playerDbData.lockedCards : []),
		mazeId: (playerDbData.hasOwnProperty('mazeId') ? playerDbData.mazeId : 1),
		missionsCompleted: (playerDbData.hasOwnProperty('missionsCompleted') ? playerDbData.missionsCompleted : []),
		numberOfLogins: (playerDbData.hasOwnProperty('numberOfLogins') ? playerDbData.numberOfLogins : []),
		onboardingPlayed: (playerDbData.hasOwnProperty('onboardingPlayed') ? playerDbData.onboardingPlayed : false),
		secretsDiscovered: (playerDbData.hasOwnProperty('secretsDiscovered') ? playerDbData.secretsDiscovered : []),
		surveys: (playerDbData.hasOwnProperty('surveys') ? playerDbData.surveys : []),
		tagDetention: (playerDbData.hasOwnProperty('tagDetention') ? playerDbData.tagDetention : {}),
		timeLog: (playerDbData.hasOwnProperty('timeLog') ? playerDbData.timeLog : {}),
		notificationToken: (playerDbData.hasOwnProperty('notificationToken') ? playerDbData.notificationToken : null),
	};

	return playerData;
};

export {
	getPlayerData
};