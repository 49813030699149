import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import appConfig from 'config/app.config';
import {tryGetToken} from 'firebase-init';
import {errorUiTexts} from 'data/ui-texts';
import apiHelper from 'helpers/api-helper';
import {getPlayerData} from 'helpers/player-data-helper';
import { getCurrentDate } from 'helpers/date-helper';
import Splash from 'components/splash/splash';
import Login from './login';
import Game from 'components/game/game';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'splash',
			prevPage: null,
			isCheckingLoginStatus: true,
			isLoggingIn: false,
			isCreatingUser: false,
			isLoggedIn: false,
			isSubscribedToPlayerData: false,
			username: '',
			password: '',
			password1: '',
			password2: '',
			userId: null,
			userTokenId: null,
			feedback: null,
			feedback2: null,
			playerData: {
				mazeId: 1,
				introSeen: false,
				onboardingPlayed: false,
				gameStarted: null,
				gameEnded: null,
				missionsCompleted: [],
				secretsDiscovered: [],
				lockedCards: [],
				surveys: []
			},
		};
		this.unsubscribeOnAuthStateChanged = null;
		this.unsubscribePlayerData = null;
		this.timeout = null;
	}

	/**
	 * Component did mount
	 */	
	componentDidMount() {
		this.checkIfLoggedIn();
	}	

	/**
	 * Component will unmount
	 */
	componentWillUnmount = ()=>  {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				user.getIdToken().then((idToken) => {
					this.setState({
						isCheckingLoginStatus: false,
						isLoggedIn: true,
						isLoggingIn: false,
						isCreatingUser: false,
						userId: user.uid,
						userTokenId: idToken,
					});
					this.subscribeToPlayerData(user.uid);
				});
			} else {
				/* User not logged in yet / just logged out */
				const page = (this.state.isLoggedIn ? 'splash' : this.state.page);
				this.setState({
					isCheckingLoginStatus: false,
					isLoggedIn: false,
					page: page
				});
			}
		});
	}

	/**
	 * Subscribe to player data
	 */
	subscribeToPlayerData = (userId) => {
		if (this.unsubscribePlayerData !== null) this.unsubscribePlayerData();
		const db = firebase.firestore();
		this.unsubscribePlayerData = db.collection(appConfig.usersDbName).doc(userId)
			.onSnapshot((doc) => {
				if (doc.exists) {
					/* Get player data */
					const playerData = getPlayerData(doc.id, doc.data());
				
					/* Autonav to game page if user has just logged in */
					let prevPage = this.state.prevPage;
					let page = this.state.page;
					if (page === 'login') {
						prevPage = page;
						page = 'game';
						this.timeout = setTimeout(() => {this.setState({prevPage: 'game'});}, 1000);
					}
						
					/* Update state */
					const playerJustLoggedIn = (this.state.isSubscribedToPlayerData ? false : true);
					this.setState({
						isSubscribedToPlayerData: true,
						playerData, 
						prevPage, 
						page
					}, () => {
						if (playerJustLoggedIn) {
							let playerUpdates = null;

							/* Update time of last login & number of logins */
							let lastLogin = getCurrentDate();
							let numberOfLogins = JSON.parse(JSON.stringify(playerData.numberOfLogins));
							numberOfLogins.push(lastLogin);
							playerUpdates = {lastLogin: lastLogin, numberOfLogins: numberOfLogins};

							/* Check if notifications are allowed */
							/* If user has not allowed/denied tokens, a permissions popup is shown */
							tryGetToken().then((currentToken) => {										
								if (
									currentToken && 
								(!playerData.notificationToken || currentToken !== playerData.notificationToken)
								) {
									/* Allowed, update stored token */
									playerUpdates.notificationToken = currentToken;
									this.updatePlayerData(playerUpdates);	
								} else {
									/* Not allowed or token already updated */
									this.updatePlayerData(playerUpdates);	
								}							
							}).catch((err) => {
								console.log('An error occurred while retrieving token. ', err);
								this.updatePlayerData(playerUpdates);	
							});
						}
					});
				} else {
					/* User does not exist, log out */
					this.handleLogout();
				}
			}, (error) => {
				console.error('could not get player data: ', error);
			});
	}

	/**
	 * Go to page
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		const prevPage = this.state.page;
		this.setState({page: page, prevPage}, () => {
			if (page === 'game') {
				this.timeout = setTimeout(() => {this.setState({prevPage: 'game'});}, 1000);
			}
			if (page === 'login') {
				this.timeout = setTimeout(() => {this.setState({prevPage: 'login'});}, 1000);
			}			
		});
	}

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
	}

	/**
	 * Update input field
	 * @param {obj} event
	 */
	handleInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		
		this.setState({
			[name]: value,
			feedback: '',
			feedback2: ''
		});
	}


	/**
	 * Login
	 */
	handleLogin = () => {
		this.setState({isLoggingIn: true}, () => {
			const username = this.state.username;
			const password = this.state.password;
			let feedback = null;

			/* Check if both fields are filled out */
			if (username.length <= 0 || password.length <= 0) {
				feedback = errorUiTexts.emptyFields;
			}

			/* Try to log in */
			if (!feedback) {
				apiHelper('login', {username: username, password: password}).then((response) => {
					if (response.status === 'success') {
						const token = response.token;
						firebase.auth().signInWithCustomToken(token).then(() => {
							this.setState({isLoggingIn: false, password: null, username: null});
						// All okay, should trigger the authStateChanged
						}).catch((error) => {
							console.error(error);
							this.setState({feedback: errorUiTexts.unknownError, isLoggingIn: false});
						});
					} else {
						console.error(response.error);
						let msg = errorUiTexts.unknownError;
						if (errorUiTexts.hasOwnProperty(response.error)) msg = errorUiTexts[response.error];
						this.setState({feedback: msg, isLoggingIn: false});
					}
				}).catch((error) => {
				/* Connnection / database error */
					console.error(error);
					this.setState({feedback: errorUiTexts.unknownError, isLoggingIn: false});
				});
			
			} else {
			/* Input error */
				this.setState({feedback: feedback, isLoggingIn: false});
			}
		});		
	}

	/**
	 * Create user
	 */
	handleCreateUser = () => {
		if (this.state.isCreatingUser) return;
		
		this.setState({isCreatingUser: true}, () => {
			let feedback2 = null;
			let username = this.state.username;
			let password1 = this.state.password1;
			let password2 = this.state.password2;
	
			/* Check if both fields are filled out */
			if (!feedback2) {
				if (username.length <= 0 || password1.length <= 0 || password2.length <= 0) {
					feedback2 = errorUiTexts.emptyFields;
				}
			}
	
			/* Check if passwords match */
			if (!feedback2) {
				if (password1 !== password2) {
					feedback2 = errorUiTexts.passwordsDoNotMatch;
				}
			}
	
			/* Check if username is valid */
			if (!feedback2) {
				if (username.length < appConfig.userNameMinLength || username.length > appConfig.userNameMaxLength) {
					feedback2 = errorUiTexts.nameLength;
				}
				if (username[0] === ' ' || username[username.length - 1] === ' ') {
					feedback2 = errorUiTexts.leadingOrTrailingSpaces;
				}
			}
	
			/* Try to create user */
			if (!feedback2) {
				apiHelper('create-user', {username, password: password1}).then((response) => {
					if (response.status === 'success') {
						let token = response.token;
						firebase.auth().signInWithCustomToken(token).then(() => {
							this.setState({isCreatingUser: false, password: null, username: null});
							// All okay, should trigger the authStateChanged
						}).catch((error) => {
							console.error(error);
							this.setState({feedback2: errorUiTexts.unknownError, isCreatingUser: false});
						});
					} else {
						console.error(response.error);
						let msg = errorUiTexts.unknownError;
						if (errorUiTexts.hasOwnProperty(response.error)) msg = errorUiTexts[response.error];
						this.setState({feedback2: msg, isCreatingUser: false});
					}
				}).catch((error) => {
					/* Connnection / database error */
					console.error(error);
					this.setState({feedback2: errorUiTexts.unknownError, isCreatingUser: false});
				});
		
			} else {
				/* Input error */
				this.setState({feedback2: feedback2, isCreatingUser: false});
			}
		});
	}

	/**
	 * Update player data
	 * @param {object} updates 
	 */
	updatePlayerData = (updates) => {
		return new Promise((resolve)=>{
			const db = firebase.firestore();
			db.collection(appConfig.usersDbName).doc(this.state.userId).update(updates).then(() => {
				resolve({status: 'ok'});
			}, (error) => {
				resolve({status: 'error', error: error});
			});
		});
	}


	/**
	 * Render 
	 * @returns 
	 */
	render = () => {
		let splashPageClass = '';
		let loginPageClass = '';
		
		if (this.state.page === 'splash') splashPageClass = 'active';
		if (this.state.page === 'login' && this.state.prevPage === 'splash') {
			splashPageClass = 'slideOut';
			loginPageClass = 'slideIn';
		}
		if (this.state.page === 'login' && this.state.prevPage === 'login') loginPageClass = 'active';
		if (this.state.page === 'game' && this.state.prevPage === 'splash') splashPageClass = 'slideOut';
		if (this.state.page === 'game' && this.state.prevPage === 'login') loginPageClass = 'slideOut';

		return (
			<React.Fragment>
				<Splash 
					// isLoading={this.props.isLoading || this.state.isCheckingLoginStatus}
					isLoading={this.props.isLoading}
					isLoggedIn={this.state.isLoggedIn} 
					splashPageClass={splashPageClass} 
					handleGoToPage={this.handleGoToPage}
				/>
				<Login 
					isLoading={
						this.props.isLoading || 
						(this.state.isLoggedIn && !this.state.isSubscribedToPlayerData)
					}
					loginPageClass={loginPageClass}
					isLoggingIn={this.state.isLoggingIn}
					isCreatingUser={this.state.isCreatingUser}
					username={this.state.username}
					password={this.state.password}
					password1={this.state.password1}
					password2={this.state.password2}
					feedback={this.state.feedback}
					feedback2={this.state.feedback2}
					handleInput={this.handleInput} 
					handleLogin={this.handleLogin}
					handleCreateUser={this.handleCreateUser}
				/>
				{(
					this.state.isLoggedIn && 
					this.state.isSubscribedToPlayerData && 
					this.state.page === 'game'
				) && <Game 
					playerData={this.state.playerData}
					areasData={this.props.areasData}
					decksData={this.props.decksData}
					missionsData={this.props.missionsData}
					surveysData={this.props.surveysData}
					cardImages={this.props.cardImages}
					updatePlayerData={this.updatePlayerData}
					handleLogout={this.handleLogout}
				/>}
			</React.Fragment>
		);
	}
}

LoginController.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	cardImages: PropTypes.array.isRequired
};

export default LoginController;