import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {deleteCookie} from 'helpers/cookie-helper';
import MainMenu from 'components/main-menu/main-menu';
import IntroController from 'components/intro/intro-controller';
import MazeGameController from 'components/maze-game/maze-game-controller';

const gamePages = {
	menu: {component: MainMenu},
	intro: {component: IntroController},
	mazeGame: {component: MazeGameController},
};

const Game = (props) => {
	const {
		playerData, 
		areasData, 
		decksData, 
		missionsData,
		surveysData, 
		cardImages, 
		updatePlayerData, 
		handleLogout
	} = props;

	/* Page, animations, game type */
	const [pageId, setPageId] = useState('menu');
	const [animateSlideIn, setAnimateSlideIn] = useState(true);
	const [isQuickGame, setIsQuickGame] = useState(false);

	/**
	 * Show page
	 * @param {string} pageId 
	 */
	const handleShowPage = (pageId, param = false) => {
		const isQuickGame = (pageId === 'mazeGame' && param === true ? true : false);
		setAnimateSlideIn(false);
		setIsQuickGame(isQuickGame);
		setPageId(pageId);
	};

	/**
	 * Skip to last maze (dev/test servers only)
	 */
	const handleSkipToLastMaze = () => {
		updatePlayerData({
			mazeId: 5,
			lockedCards: [],
			missionsCompleted: []
		}).then(() => {
			handleRefresh();
		});
	};

	/**
	 * Clear cache & user progress (dev/test servers only)
	 * @param {string} path 
	 */
	const resetGame = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		updatePlayerData({
			cardDetention: [],
			gameEnded: null,
			gameStarted: null,
			gamesPlayed: [],
			introSeen: false, 
			lockedCards: [],
			mazeId: 1,
			missionsCompleted: [], 
			numberOfLogins: [],		
			onboardingPlayed: false,
			secretsDiscovered: [], 
			surveys: [],
			timeLog: {},
			tagDetention: []
		}).then(() => {
			handleRefresh();
		});
	};

	/**
	 * Refresh page
	 */
	const handleRefresh = () => {
		// eslint-disable-next-line no-restricted-globals
		window.location.reload();
	};

	/* Get game component */
	let Component = MainMenu;
	if (gamePages.hasOwnProperty(pageId)) Component = gamePages[pageId].component;
		
	return (
		<Component
			animateSlideIn={animateSlideIn}
			missionId={null}
			isPlaying={false}
			isGameover={false}
			isQuickGame={isQuickGame}
			playerData={playerData}
			decksData={decksData}
			areasData={areasData}
			missionsData={missionsData}
			surveysData={surveysData}
			cardImages={cardImages}
			handleShowPage={handleShowPage}
			updatePlayerData={updatePlayerData}
			handleSkipToLastMaze={handleSkipToLastMaze}
			resetGame={resetGame}
			handleLogout={handleLogout}
		/>
	);
};


Game.propTypes = {
	playerData: PropTypes.object.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	cardImages: PropTypes.array.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default Game;