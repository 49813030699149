import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {cardTypes} from 'data/cards-data';
import {getImageUrl} from 'helpers/storage-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getCardUpdates, getCardTemplate} from 'helpers/admin-helper';
import Cards from './cards';

class CardsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errmsg: null,
			areas: [],
			decks: [],
			cards: [],
			missions: [],
			images: [],
			cardTypesData: [],
			tags: [],
			surveys: [],
			deckId: null,
			selectedCard: null,
			cardPreviewId: null,
			isEditingCard: false,
			isSaving: false,
			isCreatingCard: false,
			isCreatingDeck: false,
			isDeletingDeck: false,
			showDeckPopup: false
		};
		this.unsubscribeAreas = null;
		this.unsubscribeDecks = null;
		this.unsubscribeCards = null;
		this.unsubscribeMissions = null;
		this.unsubscribeUploadedImages = null;
		this.unsubscribeTags = null;
		this.unsubscribeSurveys = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let cardTypesData = [...cardTypes];
		cardTypesData.forEach((type) => {type.show = true;});

		Promise.all([
			this.subscribeToAreas(),
			this.subscribeToDecks(),
			this.subscribeToCards(),
			this.subscribeToMissions(),
			this.subscribeToUploadedImages(),
			this.subscribeToTags(),
			this.subscribeToSurveys()
		]).then(() => {
			this.setState({isLoading: false, cardTypesData});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errmsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeDecks) this.unsubscribeDecks();
		if (this.unsubscribeCards) this.unsubscribeCards();
		if (this.unsubscribeMissions) this.unsubscribeMissions();
		if (this.unsubscribeUploadedImages) this.unsubscribeUploadedImages();
	}

	/**
	 * Subscribe to areas
	 */
	subscribeToAreas = () => {
		if (this.unsubscribeAreas !== null) this.unsubscribeAreas();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeAreas = db.collection(appConfig.areasDbName).onSnapshot((querySnapshot) => {
				let areas = [];
				if (!querySnapshot.empty) {
					areas = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = parseInt(doc.id);
						return data;						
					});
					areas = sortArrayByProperty(areas, 'id', 'ASC');
					areas.forEach((area) => {area.show = true;});
				}
				this.setState({areas}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to decks
	 */
	subscribeToDecks = () => {
		if (this.unsubscribeDecks !== null) this.unsubscribeDecks();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeDecks = db.collection(appConfig.decksDbName).onSnapshot((querySnapshot) => {
				let decks = [];
				if (!querySnapshot.empty) {
					decks = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
					decks = sortArrayByProperty(decks, 'index', 'ASC');
				}
				this.setState({decks: decks}, () => {resolve({status: 'ok', decks: decks});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to cards
	 */
	subscribeToCards = () => {
		if (this.unsubscribeCards !== null) this.unsubscribeCards();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeCards = db.collection(appConfig.cardsDbName).onSnapshot((querySnapshot) => {
				let cards = [];
				if (!querySnapshot.empty) {
					cards = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
				}
				this.setState({cards: cards}, () => {resolve({status: 'ok', cards: cards});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to missions
	 */
	subscribeToMissions = () => {
		if (this.unsubscribeMissions !== null) this.unsubscribeMissions();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeMissions = db.collection(appConfig.missionsDbName).onSnapshot((querySnapshot) => {
				let missions = [];
				if (!querySnapshot.empty) {
					missions = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						return data;						
					});
				}
				missions = sortArrayByProperty(missions, 'id', 'ASC');
				this.setState({missions}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to images
	 */
	subscribeToUploadedImages = () => {
		return new Promise((resolve)=>{
			if (this.unsubscribeUploadedImages !== null) this.unsubscribeUploadedImages();
			const db = firebase.firestore();	
			this.unsubscribeUploadedImages = db.collection(appConfig.imagesDbName).onSnapshot((querySnapshot) => {
				let images = [];
				querySnapshot.docs.forEach((doc) => {
					let data = doc.data();
					data.id = data.fileName;
					data.title = data.fileName;
					data.value = data.fileName;
					images.push(data);
				});
				
				if (images.length > 0) {
					let promises = [];
					images.forEach((image) => {promises.push(getImageUrl(image.location, image.fileName));});
					Promise.all(promises).then(
						(response) => {
							response.forEach((imgData, index) => {
								if (imgData && imgData.hasOwnProperty('fileName') && imgData.hasOwnProperty('url')) {
									images[index].url = imgData.url;
								} else {
									images[index].url = null;
									console.error(imgData);
								}
							});
							this.setState({images: images}, () => {resolve({status: 'ok'});});
						});
				} else {resolve();}
			}, (error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	}

	/**
	 * Subscribe to tags
	 */
	subscribeToTags = () => {
		if (this.unsubscribeTags !== null) this.unsubscribeTags();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeTags = db.collection(appConfig.tagsDbName).onSnapshot((querySnapshot) => {
				let tags = [];
				if (!querySnapshot.empty) {
					tags = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
					tags = sortArrayByProperty(tags, 'id', 'ASC');
				}
				this.setState({tags}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to surveys
	 */
	subscribeToSurveys = () => {
		if (this.unsubscribeSurveys !== null) this.unsubscribeSurveys();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeSurveys = db.collection(appConfig.surveysDbName).onSnapshot((querySnapshot) => {
				let surveys = [];
				if (!querySnapshot.empty) {
					surveys = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
					surveys = sortArrayByProperty(surveys, 'index', 'ASC');
				}
				this.setState({surveys: surveys}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Show / hide deck popup
	 */
	toggleDeckPopup = (showDeckPopup) => {
		this.setState({showDeckPopup, showCardPreview: false});
	}

	/**
	 * Show / hide cards in specific maze
	 * @param {number} areaIndex 
	 */
	toggleArea = (areaIndex) => {
		let areas = [...this.state.areas];
		areas[areaIndex].show = !areas[areaIndex].show;
		this.setState({areas});
	}

	/**
	 * Show / hide cards of specific type
	 * @param {number} typeIndex 
	 */
	toggleType = (typeIndex) => {
		let cardTypesData = [...this.state.cardTypesData];
		cardTypesData[typeIndex].show = !cardTypesData[typeIndex].show;
		this.setState({cardTypesData});
	};

	/**
	 * Create new deck
	 * @param {object} newDeckData 
	 */
	createNewDeck = (newDeckData) => {
		if (this.state.isCreatingDeck) return;
		
		this.setState({isCreatingDeck: true}, () => {
			let newDeckObj = {title: newDeckData.title, index: this.state.decks.length};
			let newDeckId =  newDeckData.id;

			const db = firebase.firestore();
			db.collection(appConfig.decksDbName).doc(newDeckId).set(newDeckObj).then(() => {
				this.setState({isCreatingDeck: false});
			}, (error) => {
				console.error(error);
				this.setState({isCreatingDeck: false});
			});
		});
	}

	/**
	 * Delete deck
	 * @param {string} deckId 
	 */
	deleteDeck = (deckId) => {
		if (this.state.isDeletingDeck) return;
		this.setState({isDeletingDeck: true}, () => {
			const db = firebase.firestore();
			db.collection(appConfig.decksDbName).doc(deckId).delete().then(() => {
				this.setState({isDeletingDeck: false});
			}, (error) => {
				console.error(error);
				this.setState({isDeletingDeck: false});
			});
		});
	}

	/**
	 * Select deck
	 * @param {object} event
	 */
	selectDeck = (event) => {
		this.setState({[event.target.name]: event.target.value});
	}

	/**
	 * Select card
	 * @param {string} cardId 
	 */
	selectCard = (cardId) => {
		if (this.state.cards.some((card) => {return card.id === cardId;})) {
			let selectedCard = this.state.cards.filter((card) => {return card.id === cardId;})[0];
			this.setState({selectedCard});
			// window.scrollTo(0, 0);
		} else {
			this.setState({selectedCard: null, isEditingCard: false});
		}
	}

	/**
	 * Update card
	 * @param {object} event 
	 * @param {string} answerType 
	 * @param {string} obj 
	 */
	updateCard = (event, answerType = null, obj = null, obj2 = null) => {
		if (this.state.isSaving) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedCard = JSON.parse(JSON.stringify(this.state.selectedCard));

		if (answerType && selectedCard.hasOwnProperty(answerType)) {
			if (obj) {
				if (
					!selectedCard[answerType].hasOwnProperty(obj) || 
					!selectedCard[answerType][obj] ||
					typeof selectedCard[answerType][obj] !== 'object'
				) {
					selectedCard[answerType][obj] = {};
				}
				if (obj2) {
					if (
						!selectedCard[answerType][obj].hasOwnProperty(obj2) || 
						!selectedCard[answerType][obj][obj2] ||
						typeof selectedCard[answerType][obj][obj2] !== 'object'
					) {
						selectedCard[answerType][obj][obj2] = {};
						if (obj === 'feedback' && obj2 === 'condition') {
							selectedCard[answerType][obj][obj2] = {
								type: 'secret', 
								secretId: selectedCard[answerType].mission.secretId
							};
						}
					}
					selectedCard[answerType][obj][obj2][name] = value;
				} else {
					
					if (obj === 'stats') {
						selectedCard[answerType][obj][name] = parseInt(value);
					} else if (obj === 'mission' && name === 'locksCards') {
						selectedCard[answerType][obj][name] = [value];
					} else {
						if (obj === 'survey' && name === 'surveyType' && value !== 'specific') {
							selectedCard[answerType][obj]['surveyId'] = null;	
						}
						selectedCard[answerType][obj][name] = value;
					}
				}
			} else {
				selectedCard[answerType][name] = value;		
			}
		} else {
			if (name === 'mazeId') {
				selectedCard[name] = parseInt(value);
			} else if (name === 'addTag') {
				if (!selectedCard.hasOwnProperty('tags')) selectedCard.tags = [];
				selectedCard.tags.push(value);
			} else if (name === 'removeTag') {
				if (!selectedCard.hasOwnProperty('tags')) selectedCard.tags = [];
				const tagIndex = selectedCard.tags.indexOf(value);
				if (tagIndex >= 0) selectedCard.tags.splice(tagIndex, 1);
			} else {
				selectedCard[name] = value;
			}
			
		}
		
		this.setState({selectedCard, isEditingCard: true});
	}

	/**
	 * Save changes
	 */
	saveCard = () => {
		/* Save card updates */
		if (this.state.selectedCard && this.state.isEditingCard && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let cardUpdates = getCardUpdates(this.state.selectedCard, this.state.missions);
				const db = firebase.firestore();
				db.collection(appConfig.cardsDbName).doc(this.state.selectedCard.id).update(cardUpdates).then(() => {
					this.setState({isEditingCard: false, isSaving: false});
				}, (error) => {
					console.error(error);
					this.setState({isSaving: false});
				});
			});
		}
	}

	/**
	 * Show / hide card preview
	 * @param {string} cardId 
	 */
	showCardPreview = (cardId) => {
		this.setState({cardPreviewId: cardId});
		// if (cardId) window.scrollTo(0, 0);
	}

	/**
	 * Create new card
	 * @param {string} deckId 
	 */
	createNewCard = (deckId) => {
		if (this.state.isCreatingCard) return;

		this.setState({isCreatingCard: true}, () => {
			/* Generate card id */
			let cardsInDeck = this.state.cards.filter((card) => {return card.deckId === deckId;});
			let cardIndex = 1;
			if (cardsInDeck.length > 0) {
				cardsInDeck = sortArrayByProperty(cardsInDeck, 'id', 'DESC');
				cardIndex = (cardsInDeck[0].id.split('-').length > 1 
					? parseInt(cardsInDeck[0].id.split('-')[1]) + 1
					: (cardsInDeck.length + 1)
				);
			}

			/* Check card id does not already exist */
			let cardIdExists = true;
			let numberOfIterations = 0;
			while (cardIdExists && numberOfIterations < 1000) {
				numberOfIterations += 1;
				let cardId = deckId + '-' + cardIndex;
				cardIdExists = cardsInDeck.some((card) => {return card.id === cardId;});
				if (cardIdExists) cardIndex += 1;
			}

			if (!cardIdExists) {
				/* Create new card */
				let cardId = deckId + '-' + cardIndex;
				let newCardObj = getCardTemplate(deckId);
				const db = firebase.firestore();
				db.collection(appConfig.cardsDbName).doc(cardId).set(newCardObj).then(() => {
					this.setState({isCreatingCard: false});
				}, (error) => {
					console.error(error);
					this.setState({isCreatingCard: false});
				});
			} else {
				console.error('Error creating card');
			}
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		let cardsInDeck = [];
		if (this.state.deckId) {
			cardsInDeck = this.state.cards.filter((card) => {return card.deckId === this.state.deckId;});
			cardsInDeck = sortArrayByProperty(cardsInDeck, 'index', 'ASC');
		}

		let selectedCard = this.state.selectedCard;
		if (this.state.cardPreviewId !== null) {
			selectedCard = this.state.cards.filter((card) => {return card.id === this.state.cardPreviewId;})[0];
		}
		
		return (
			<Cards 
				isEditingCard={this.state.isEditingCard}
				isSaving={this.state.isSaving}
				isCreatingCard={this.state.isCreatingCard}
				isCreatingDeck={this.state.isCreatingDeck}
				showDeckPopup={this.state.showDeckPopup}
				areas={this.state.areas}
				decks={this.state.decks}
				cards={this.state.cards}
				missions={this.state.missions}
				images={this.state.images}
				cardTypesData={this.state.cardTypesData}
				tags={this.state.tags}
				surveys={this.state.surveys}
				deckId={this.state.deckId}
				cardsInDeck={cardsInDeck}
				selectedCard={selectedCard}
				cardPreviewId={this.state.cardPreviewId}
				toggleDeckPopup={this.toggleDeckPopup}
				toggleArea={this.toggleArea}
				toggleType={this.toggleType}
				selectDeck={this.selectDeck}
				selectCard={this.selectCard}
				updateCard={this.updateCard}
				saveCard={this.saveCard}
				showCardPreview={this.showCardPreview}
				createNewDeck={this.createNewDeck}
				deleteDeck={this.deleteDeck}
				createNewCard={this.createNewCard}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

CardsController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default CardsController;